import React, { Component } from "react";

function fisherYatesShuffle(array) {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export default class Referrals extends Component {
  state = {
    partners: [
      {
        name: "Quizmoji",
        url: "https://quizmoji.com/?utm_source=wordlockgame",
        logo: "quizmoji.png",
      },
      {
        name: "Decodex",
        url: "https://www.playdecodex.com/?utm_source=wordlockgame",
        logo: "decodex.png",
      },
      {
        name: "Puzzmallow",
        url: "https://puzzmallow.com/?utm_source=wordlockgame",
        logo: "puzzmallow.png",
      },
      {
        name: "sqnces",
        url: "https://sqnces.com/?utm_source=wordlockgame",
        logo: "sqnces.svg",
      },
      {
        name: "Dodeku",
        url: "https://dodeku.com/?utm_source=wordlockgame",
        logo: "dodeku.png",
      },
      {
        name: "Mojie Quiz",
        url: "https://mojiequiz.com/?utm_source=wordlockgame",
        logo: "mojiequiz.png",
      },
      {
        name: "Eightile",
        url: "https://eightile.com/?utm_source=wordlockgame",
        logo: "eightile.png",
      },
      {
        name: "Couplit",
        url: "https://couplit.io/?utm_source=wordlockgame",
        logo: "couplit.png",
      },
      {
        name: "Tracell",
        url: "https://tracellgame.com/?utm_source=wordlockgame",
        logo: "tracell.png",
      },
      {
        name: "WordLink",
        url: "https://playwordlink.com/?utm_source=wordlockgame",
        logo: "wordlink.png",
      },
      {
        name: "Wordloop",
        url: "https://word-loop.com/?utm_source=wordlockgame",
        logo: "wordloop.png",
      },
    ],
  };

  render() {
    const shuffledPartners = fisherYatesShuffle(this.state.partners);

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <span>other games we love</span>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {shuffledPartners.slice(0, 3).map((partner) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
              key={partner.name}
              onClick={() => {
                window.open(partner.url, "_blank", "noopener,noreferrer");
              }}
            >
              <img
                src={`/assets/partners/${partner.logo}`}
                alt={partner.name}
                style={{ width: 32, height: 32 }}
              />
              <span style={{ fontFamily: "sans-serif", fontWeight: 500 }}>
                {partner.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
